import ProgressBar from "components/progressBar";
import CountDown from "components/countDown";
import Button from "components/button";
import ProjectInfoStyleWrapper from "./ProjectInfo.style";

import projectIcon from "assets/images/project/ninga-image.png"
import coinIcon from "assets/images/project/icon-2.png"
import socialData from "assets/data/social/dataV1"

const ProjectInfo = ({ loan, interest, nearLoans, isSignedIn, wallet }) => {

  const now = Date.now() * 1000000
  const collected = loan.collected ? loan.collected : 0
  const collectable = Math.floor(loan.capital * (1 + loan.rate * 0.01))
  const createdAt = new Date(loan.createdAt / 1000000) 
  const expiredAt = new Date(loan.expiredAt / 1000000) 
  const hasExpired = now >= loan.expiredAt
  const availableDuration = loan.expiredAt - loan.createdAt
  const elapsedSinceCreation = now - loan.createdAt
  const expiresIn = Math.floor(( loan.expiredAt - loan.createdAt) / 1000000000 )
  const durationNs = parseInt(loan.duration) * 86400000000000
  const acceptedAt = loan.acceptedAt ? parseInt(loan.acceptedAt) : 0
  const maturesAt = acceptedAt + durationNs
  const maturesIn = Math.floor((maturesAt - now) / 1000000000)

  let progress
  switch(loan.status) {
    case "PENDING":
      progress = Math.min(100 * elapsedSinceCreation / availableDuration, 100)
    break;
    case "ACTIVE":
      progress = Math.min(100 * collected / collectable, 100)
    break;
  }

  if(isSignedIn) {
    console.log(wallet.accountId)
  }

  const settings = {
    showTitle: true,
    size: 30,
    labelSize: 20,
    direction: "right",
    backgroundColor: "transparent",
    color: "#fff",
    dayTitle: "D",
    hourTitle: "H",
    minuteTitle: "M",
    secondTitle: "S",
    id: "countdownwrap",
  };

  const acceptLoan = async () => {
    if(hasExpired) return

    try {
        const result = await nearLoans.acceptLoan(loan)
        alert(result)
    } catch (err) {
        alert("an error occured")
        console.log("error creating loan")
        console.log(err)
    }
  }

  const cancelLoan = async () => {
    try {
        const result = await nearLoans.cancelLoan(loan)
        alert(result)
    } catch (err) {
        alert("an error occured")
        console.log("error canceling loan")
        console.log(err)
    }
  }

  const increaseLoanDeposit = async () => {
    try {
        const result = await nearLoans.increaseLoanDeposit(loan)
        alert(result)
    } catch (err) {
        alert("an error occured")
        console.log("error creating loan")
        console.log(err)
    }
  }

  const collectLoanInterest = async () => {
    try {
        const result = await nearLoans.collectLoanInterest(loan)
        alert(result)
    } catch (err) {
        alert("an error occured")
        console.log("error creating loan")
        console.log(err)
    }
  }

  const repayLoan = async () => {
    try {
        const result = await nearLoans.repayLoan(loan)
        alert(result)
    } catch (err) {
        alert("an error occured")
        console.log("error creating loan")
        console.log(err)
    }
  }

  const defaultLoan = async () => {
    try {
        const result = await nearLoans.defaultLoan(loan)
        alert(result)
    } catch (err) {
        alert("an error occured")
        console.log("error creating loan")
        console.log(err)
    }
  }
  
  return (
    <ProjectInfoStyleWrapper className="live_project_wrapper">
      <div className="game-price-item">
        <div className="game-price-inner">
          <div className="total-price">
            <div className="price-inner d-flex">
              <div className="image-icon">
                <img src={projectIcon} alt="icon" />
              </div>
              <div className="price-details">
                <h3>
                  {loan && <a>{loan.capital} {loan.currency}</a>}
                </h3>
                {loan && <div className="dsc">INTEREST RATE : {loan.rate} %</div>}
                {loan && isSignedIn && loan.borrower == wallet.accountId && <div className="dsc">CURRENT DEPOSIT : {loan.deposit ? loan.deposit : 0 } {loan.currency}</div>}
                {loan && isSignedIn && loan.lender == wallet.accountId && <div className="dsc">COLLECTABLE : {interest ? interest.collectable : 0} {loan.currency}</div>}                
                {/* {loan && <div className="dsc">FREQUENCY : {loan.frequency} DAYS</div>} */}
              </div>
            </div>
            {/* <div className="all-raise">
              Total Raise 75,999.70 BUSD ( 86% )
            </div> */}
          </div>
          {/* <div className="allocation-max text-center">
            <img src={coinIcon} alt="icon" />
            <div className="allocation">
              Allocation: 500 BUSD Max
            </div>
          </div> */}
          {loan.status == "PENDING" && !hasExpired && <div className="targeted-raise">
            <div className="seles-end-text">Loan request expires in </div>
            <CountDown {...settings} count={expiresIn} />
          </div>}
          {loan.status == "PENDING" && hasExpired && <div className="targeted-raise">
            <div className="seles-end-text danger">Loan request already expired</div>
          </div>}
          {loan.status == "ACTIVE" && <div className="targeted-raise">
            <div className="seles-end-text">Loan matures in </div>
            <CountDown {...settings} count={maturesIn} />
          </div>}
        </div>
        <div className="progress-inner">
          <div className="interest-amounts">
            {loan.status == "ACTIVE" && <div className="collected-interest-amount">
              Repaid : {collected} {loan.currency}
            </div>}
            {loan.status == "ACTIVE" && <div className="total-interest-amount">
              Total Repayment : {collectable} {loan.currency}
            </div>}
            {loan.status == "PENDING" && createdAt && <div className="collected-interest-amount">
              Created At : {createdAt.toLocaleDateString()}
            </div>}
            {loan.status == "PENDING" && expiredAt && <div className="total-interest-amount">
              Expires At : {expiredAt.toLocaleDateString()}
            </div>}
          </div>
          { (loan.status == "PENDING" || loan.status == "ACTIVE" ) && <ProgressBar progress={progress+"%"} />}
        </div>

        <div className="project_card_footer">
          {loan.status == "PENDING" && isSignedIn && loan.borrower != wallet.accountId && <Button sm type="button" isDisabled={hasExpired} onClick={acceptLoan}>
            Accept Loan
          </Button>}
          {loan.status == "PENDING" && isSignedIn && loan.borrower == wallet.accountId && <Button sm type="button" onClick={cancelLoan}>
            Cancel Loan
          </Button>}
          {loan.status == "ACTIVE" && isSignedIn && loan.borrower == wallet.accountId && <Button sm type="button" onClick={increaseLoanDeposit}>
            Increase Deposit
          </Button>}          
          {loan.status == "ACTIVE" && isSignedIn && loan.borrower == wallet.accountId && <Button sm type="button" onClick={defaultLoan}>
            Default Loan
          </Button>}
          {loan.status == "ACTIVE" && isSignedIn && loan.borrower == wallet.accountId && <Button sm type="button" onClick={repayLoan}>
            Repay Loan
          </Button>}
          {loan.status == "ACTIVE" && isSignedIn && loan.lender == wallet.accountId && <Button sm type="button" onClick={collectLoanInterest}>
            Collect Interest
          </Button>}

          {/* <div className="participants">Participants 4017/5000</div>
          <div className="social_links">
            {socialData?.map((profile, i) => (
              <a key={i} href={profile.url}><img src={profile.icon} alt="icon" /></a>
            ))}
          </div> */}
        </div>
      </div>
    </ProjectInfoStyleWrapper>
  );
};

export default ProjectInfo;
