/* Talking with a contract often involves transforming data, we recommend you to encapsulate that logic into a class */
const ONE_YOCTO = 1 //0.000000000000000000000001

export class NearLoans {
  constructor({ contractId, walletToUse, ftContractIds }) {
    this.ftContractIds = ftContractIds
    this.contractId = contractId;
    this.wallet = walletToUse;    
  }

  async getGreeting() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: 'get_greeting' });
  }

  async setGreeting(message) {
    return await this.wallet.callMethod({ contractId: this.contractId, method: 'set_greeting', args: { message } });
  }

  async createLoan(mode, type, currency, ft, capital, rate, frequency, duration, expiration) {
    // return await this.wallet.callMethod({ contractId: this.contractId, method: 'create_loan', args: { mode, type, currency, capital, rate, frequency, duration, expiration } });
    const ftContractId = ft ? ft : this.ftContractIds[currency]

    const msg = JSON.stringify({ operation: "create_loan", params: { currency, ft, capital, rate, frequency, duration } })
    
    return await this.wallet.callMethod({ 
      contractId: ftContractId, 
      method: 'ft_transfer_call', 
      args: { receiver_id: this.contractId, amount: capital, msg },
      gas: 300000000000000,
      deposit: ONE_YOCTO
    });
  }

  async getLoan(id) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: 'get_loan', args: { id } });
  }

  async getLoans() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: 'get_loans' });
  }

  async getStats() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: 'get_stats' });
  }
  
  async cancelLoan(id) {
    return await this.wallet.callMethod({ contractId: this.contractId, method: 'cancel_loan', args: { id } });
  }

  async getBorrowers() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: 'get_borrowers' });
  }

  async getLenders() {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: 'get_lenders' });
  }  

  async acceptLoan(loan) {
    // return await this.wallet.callMethod({ contractId: this.contractId, method: 'accept_loan', args: { id } });
    const { id, currency, ft, capital } = loan
    const ftContractId = ft ? ft : this.ftContractIds[currency]
    const msg = JSON.stringify({ operation: "accept_loan", params: { id } })
    
    return await this.wallet.callMethod({ 
      contractId: ftContractId, 
      method: 'ft_transfer_call', 
      args: { receiver_id: this.contractId, amount: capital, msg },
      gas: 300000000000000,
      deposit: ONE_YOCTO
    });
  }

  async cancelLoan(loan) {
    const { id } = loan
    return await this.wallet.callMethod({ contractId: this.contractId, method: 'cancel_loan', args: { id } });
  }

  async collectLoanInterest(loan) {
    const { id } = loan

    return await this.wallet.callMethod({ 
      contractId: this.contractId, 
      method: 'collect_loan_interest', 
      args: { id }, 
      gas: 300000000000000,
      deposit: ONE_YOCTO
    });
  }

  async getLoanInterest(id) {
    return await this.wallet.viewMethod({ contractId: this.contractId, method: 'get_loan_interest', args: { id } });
  }

  async increaseLoanDeposit(loan) {
    const { id, currency, ft, capital, rate, duration } = loan
    // const totalPayments = Math.ceil(parseInt(duration) / parseInt(frequency))
    const totalCost = parseInt(capital) * (1 + parseFloat(rate * 0.01))
    const amount = Math.ceil(totalCost / duration) 
    const ftContractId = ft ? ft : this.ftContractIds[currency]
    const msg = JSON.stringify({ operation: "increase_loan_deposit", params: { id } })

    return await this.wallet.callMethod({ 
      contractId: ftContractId, 
      method: 'ft_transfer_call', 
      args: { receiver_id: this.contractId, amount: "" + amount, msg },
      gas: 300000000000000,
      deposit: ONE_YOCTO
    });
  }

  async repayLoan(loan) {
    const { id } = loan
    return await this.wallet.callMethod({ contractId: this.contractId, method: 'repay_loan', args: { id } });
  }

  async defaultLoan(loan) {
    const { id } = loan
    return await this.wallet.callMethod({ contractId: this.contractId, method: 'collect_loan_interest', args: { id } });
  }
}

