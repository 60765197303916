import CardHover from "components/cardHover";
import ProjectCardStyleWrapper from "./ProjectCard.style";
import { DaysAgo } from "utils/Time";

const ProjectCard = ({
  thumb,
  title,
  price,
  saleEnd,
  coinIcon,
  projectDetails,
  socialLinks,
  id,
  borrower,
  currency,
  capital,
  rate,
  frequency,
  duration,
  createdAt,
  status
}) => {
  return (
    <ProjectCardStyleWrapper className="project_item_wrapper">
      <div className="project-info d-flex">
        <a href={"/projects-details-1/" + id}>
          <img src={coinIcon} alt="project thumb" />
        </a>
        <div className="project-auother">
          <h4 className="mb-10">
            <a href={"/projects-details-1/" + id}>{currency}</a>
          </h4>
          <div className="dsc">CAPITAL : {capital} {currency}</div>
        </div>
      </div>
      <div className="project-content">
        <div className="project-header d-flex justify-content-between align-items-center">
          <div className="heading-title">
            <h4>{DaysAgo(createdAt)}</h4>
          </div>
          {/* <div className="project-icon">
            <img src={coinIcon} alt="coin icon" />
          </div> */}
        </div>
        <ul className="project-listing">
          <li key="1">capital<span>{capital} {currency}</span></li>
          <li key="2">rate<span>{rate}&nbsp;%</span></li>
          <li key="3">duration<span>{duration} days</span></li>
          {/* {projectDetails?.map((item, i) => (
            <li key={i}>
              {item.title} <span>{item.text}</span>
            </li>
          ))} */}
        </ul>
        <div className="social-links">
          {socialLinks?.map((profile, i) => (
            <a key={i} href={profile.url}><img src={profile.icon} alt="social icon" /></a>
          ))}
        </div>
      </div>

      <CardHover />
    </ProjectCardStyleWrapper>
  );
};

export default ProjectCard;
