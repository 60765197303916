import thumb1 from "assets/images/icons/Key_icon1.png";
import thumb2 from "assets/images/icons/Key_icon2.png";
import thumb3 from "assets/images/icons/Key_icon3.png";
import thumb4 from "assets/images/icons/Key_icon4.png";

const data = [
  {
    icon: thumb1,
    title: "Decentralized",
    text: "NearLoans is running on Near blockchain, it thus benefits all the advantages of decentralization.",
  },
  {
    icon: thumb2,
    title: "Collateralized",
    text: "On NearLoans, every loan is collateralized. If the loan is defaulted, the lender will always be fully repaid on capital while keeping the interests already collected during the loan life.",
  },
  {
    icon: thumb3,
    title: "Customizable",
    text: "Loans settings are fully customizable. Define exactly the way you want to borrow / lend assets on Near blockchain, according to your needs.",
  },
  {
    icon: thumb4,
    title: "Manageable",
    text: "All of the borrowing / lending process is easily manageable through the graphic interface following simple steps. No much technical knowledge is needed to use NearLoans.",
  },
];

export default data;
