import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "utils/ContextProvider";
import App from "app/App";

// NEAR
import { NearLoans } from './near/near-interface';
import { Wallet } from './near/near-wallet';

// import ReactGA from 'react-ga';

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

// When creating the wallet you can optionally ask to create an access key
// Having the key enables to call non-payable methods without interrupting the user to sign
const wallet = new Wallet({ createAccessKeyFor: process.env.REACT_APP_CONTRACT_NAME })

// Abstract the logic of interacting with the contract to simplify your flow
const nearLoans = new NearLoans({ contractId: process.env.REACT_APP_CONTRACT_NAME, walletToUse: wallet, ftContractIds: JSON.parse(process.env.REACT_APP_FT_CONTRACT_NAMES) });

// Setup on page load
window.onload = async () => {
  const isSignedIn = await wallet.startUp()
  
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <ContextProvider>
      <BrowserRouter>
        <App isSignedIn={isSignedIn} nearLoans={nearLoans} wallet={wallet} />
      </BrowserRouter>
    </ContextProvider>
  );
}

