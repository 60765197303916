import styled from "styled-components";

const ProjectDetailsStyleWrapper = styled.div`
  position: relative;
  background: #090a1a;

  .page_header_wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }

  .token_info_row {
    margin-top: 30px 0 55px;
    row-gap: 30px;

    @media (max-width: 767px) {
    padding-bottom: 20px;
    }
  }
`;

export default ProjectDetailsStyleWrapper;
