import { Fragment, useState, useEffect } from "react";
import { useModal } from "utils/ModalContext";
import GlobalStyles from "assets/styles/GlobalStyles"
import Layout from "components/layout";
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import Header from "sections/Header/v2";
import PageHeader from "sections/ProjectPages/ProjectsList/PageHeader";
import ProjectsList from "sections/ProjectPages/ProjectsList";
import Footer from "sections/Footer/v1";

export default function ProjectListPage({ isSignedIn, wallet, nearLoans }) {
  const { walletModalvisibility, metamaskModal } = useModal();
  const [loans, setLoans] = useState();

  // Get blockchian state once on component load
  useEffect(() => {
    nearLoans.getLoans()
      .then(setLoans)
      .catch(alert)
  }, []);

  return (
    <Fragment>
      <GlobalStyles />
      <Layout>
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        <Header />
        <PageHeader currentPage="LOANS" pageTitle="EXPLORE LOANS" />
        {loans && <ProjectsList loans={loans} />}
        <Footer />
      </Layout>
    </Fragment>
  );
}
