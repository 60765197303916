import ProjectItems from "./ProjectItems/ProjectItems"
import ProjectsListStyleWrapper from "./ProjectsList.style"

const ProjectsList = ({ loans }) => {
    return (
        <ProjectsListStyleWrapper>  
            <ProjectItems loans={loans} />
        </ProjectsListStyleWrapper>
    )
}

export default ProjectsList;