const data = [
  {
    title: "Funded Projects",
    value: "386",
  },
  {
    title: "Unique Participants",
    value: "486",
  },
  {
    title: "Raised Capital",
    value: "9",
  },
  {
    title: "Initial Market Cap",
    value: "18",
  },
];

export default data;
