export function DaysAgo(timestampNano) {
    const timestamp = Math.round(timestampNano/1000000)
    const now = new Date().getTime()

    const rtf = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
    });
    const oneDayInMs = 1000 * 60 * 60 * 24;
    const daysDifference = Math.round(
        (timestamp - now) / oneDayInMs,
    );

    const daysAgo = rtf.format(daysDifference, 'day');

    return daysAgo
}

export function InDays(timestampNano) {
    const timestamp = Math.round(timestampNano/1000000)
    const now = new Date().getTime()

    const rtf = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
    });
    const oneDayInMs = 1000 * 60 * 60 * 24;
    const daysDifference = Math.round(
        (now - timestamp) / oneDayInMs,
    );

    const inDays = rtf.format(daysDifference, 'day');

    return inDays
}