import { useState } from "react";
import Sidebar from "../SideBar"
import ProjectInfo from "../ProjectInfo/"
import TokenInfo from "../TokenInfo/TokenInfo"
import Summary from "../Summary/Summary"
import Schedule from "../Schedule/Schedule"
import Comparison from "../Comparison/Comparison"
import Statistics from "../Statistics"
import RoadMap from "../RoadMap/RoadMap"
import Team from "../Team"
import Partner from "../Investor"

import ProjectDetailsStyleWrapper from "./ProjectDetails.style"
import tokenData from "assets/data/token/dataV2"

const ProjectDetails = ({ loan, interest, nearLoans, isSignedIn, wallet }) => {
    const basicInfos = [
        { title: "Capital", text: loan.capital},          
        { title: "Currency", text: loan.currency},
        { title: "Status", text: loan.status},
        { title: "Borrower", text: loan.borrower},
        { title: "Lender", text: loan.lender}
    ]

    const technicalData = [
        { title: "APY", text: loan.capital+ " " + loan.currency},          
        { title: "Total Cost", text: loan.capital * loan.rate + " " + loan.currency},
        { title: "Interest rate", text: loan.rate + " %"},
        { title: "Interest frequency", text: loan.frequency},
        { title: "Duration", text: loan.duration},
    ]

    return (
        <ProjectDetailsStyleWrapper>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ProjectInfo loan={loan} interest={interest} nearLoans={nearLoans} isSignedIn={isSignedIn} wallet={wallet} />
                    </div>
                </div>
                <div className="row token_info_row">
                    <div key={1} className="col-md-6 col-sm-12">
                        <TokenInfo title="Basic Informations" infos={basicInfos} />
                    </div>
                    <div key={2} className="col-md-6 col-sm-12">
                        <TokenInfo title="Technical Data" infos={technicalData} />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-4">
                        <Sidebar />
                    </div>
                    <div className="col-md-8">
                        <Summary />
                        <Schedule />
                        <Comparison />
                        <Statistics />
                        <RoadMap />
                        <Team />
                        <Partner />
                    </div>
                </div> */}
            </div>
        </ProjectDetailsStyleWrapper>
    )
}

export default ProjectDetails;