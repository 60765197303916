import ProgressBar from "components/progressBar";
import CardHover from "components/cardHover";
import ProjectCardStyleWrapper from "./ProjectCard.style";
import { DaysAgo, InDays } from "utils/Time";

const ProjectCard = ({
  thumb,
  title,
  price,
  launchedDate,
  totalRised,
  progress,
  saleEnd,
  coinIcon,
  projectDetails,
  socialLinks,
  id,
  borrower,
  currency,
  capital,
  rate,
  frequency,
  createdAt,
  status
}) => {
  return (
    <ProjectCardStyleWrapper>
      <div className="previous-item hover-shape-border hover-shape-inner">
        <div className="previous-gaming previous-subitem">
          <div className="previous-image">
            <img src={coinIcon} alt="Previous item thumb" />
          </div>
          <div className="previous-price">
            <h4 className="mb-10">
              <a href={"/projects-details-1/"+id}>
                {currency}
              </a>
            </h4>
            {/* <div className="dsc">PRICE (FSC) = {price} BUSD</div> */}
          </div>
        </div>
        <div className="previous-chaining previous-subitem">
          {/* <img src={coinIcon} alt="Chain icon" /> */}
          <span>{capital}&nbsp;{currency}</span>
        </div>
        <div className="previous-raise previous-subitem">
          <span>
            {rate} %
            {/* {totalRised} BUSD ({progress}) */}
          </span>
          {/* <ProgressBar progress={progress} /> */}
        </div>
        <div className="previous-raise previous-subitem">
          <span>
            {frequency} days
          </span>
        </div>
        <div className="previous-raise previous-subitem">
          <span>
            {InDays(createdAt)}
          </span>
        </div>
        <CardHover />
      </div>
    </ProjectCardStyleWrapper>
  );
};

export default ProjectCard;
