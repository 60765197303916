const data = [
  {
    title: "Team",
    value: "7.5",
  },
  {
    title: "Staking",
    value: "9.50",
  },
  {
    title: "Advisors",
    value: "10.00",
  },
  {
    title: "Liquidity",
    value: "12.00",
  },
  {
    title: "Ecosystem",
    value: "16.33",
  },
  {
    title: "Marketing",
    value: "18.00",
  },
  {
    title: "Private Sale",
    value: "30.00",
  },
];

export default data;
