import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { SectionTitle, SectionTitleWrapper } from "components/sectionTitle";
import Button from "components/button";
import ProjectCard from "./ProjectCard/ProjectCard";
import ExploreProjectsStyleWrapper from "./ExploreProjects.style";
import coinIcon from "assets/images/project/project-single-image.png";
import projectData from "assets/data/projects/upcommingProjectsV2";

const ExploreProjects = ({ loans }) => {
  
  const data = !loans ? [] : [{
    loanStatus: "PENDING",
    loans: loans.filter(l => l.status == "PENDING").map(l => Object.assign(l, { coinIcon }))
  }, {
    loanStatus: "ACTIVE",
    loans: loans.filter(l => l.status == "ACTIVE").map(l => Object.assign(l, { coinIcon }))
  }, {
    loanStatus: "DEFAULTED",
    loans: loans.filter(l => l.status == "DEFAULTED").map(l => Object.assign(l, { coinIcon }))
  }, {
    loanStatus: "REPAID",
    loans: loans.filter(l => l.status == "REPAID").map(l => Object.assign(l, { coinIcon }))
  }]

  return (
    <ExploreProjectsStyleWrapper>
      <div className="container">
        <SectionTitleWrapper>
          <SectionTitle title="LOAN POOLS" subtitle="EXPLORE" />
        </SectionTitleWrapper>
        {data && <div className="row align-items-center single-project-row">
           <Tabs>
            <TabList>
              {data?.map((child, i) => (
                <Tab key={i}>
                  <Button
                    variant="outline"
                    sm
                    onClick={(e) => e.preventDefault()}
                  >
                    {child.loanStatus}
                  </Button>
                </Tab>
              ))}
            </TabList>

            {data?.map((items, i) => (
              <TabPanel key={i} className="row tabs-row">
                {items.loans?.map((loan, i) => (
                  <div key={i} className="col-lg-4 col-md-6">
                    <ProjectCard key={i} {...loan} />
                  </div>
                ))}
              </TabPanel>
            ))}
          </Tabs>
        </div>}
      </div>
    </ExploreProjectsStyleWrapper>
  );
};

export default ExploreProjects;
