import { Fragment, useState, useEffect } from "react";
import { useModal } from "utils/ModalContext";
import Layout from "components/layout";
import GlobalStyles from "assets/styles/GlobalStyles"
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import ShareModal from "components/modal/shareModal/ShareModal";
import Header from "sections/Header/v2";
import PageHeader from "sections/ProjectPages/ProjectDetails/v1/PageHeader";
import ProjectDetails from "sections/ProjectPages/ProjectDetails/v1";
import Footer from "sections/Footer/v1";
import { useParams } from "react-router-dom";

export default function ProjectsDetailsOne({ isSignedIn, wallet, nearLoans }) {
  const { walletModalvisibility, metamaskModal, shareModalVisibility } = useModal();
  const [loan, setLoan] = useState();
  const [interest, setInterest] = useState();
  const { id } = useParams();

  // Get blockchian state once on component load
  useEffect(() => {
      nearLoans.getLoan(id)
      .then(setLoan)
      // .catch(alert)
  }, []);

  useEffect(() => {
    nearLoans.getLoanInterest(id)
      .then(setInterest)
      // .catch(alert)
  }, []);

  return (
    <Fragment>
      <Layout>
        <GlobalStyles />
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        {shareModalVisibility && <ShareModal />}
        <Header isSignedIn={isSignedIn} wallet={wallet} />
        <PageHeader currentPage="LOAN DETAILS " />
        {loan && <ProjectDetails loan={loan} interest={interest} nearLoans={nearLoans} isSignedIn={isSignedIn} wallet={wallet} />}
        <Footer />
      </Layout>
    </Fragment>
  );
}
