import CardHover from "components/cardHover";
import ProjectCardStyleWrapper from "./ProjectCard.style";

const ProjectCard = ({
  coinIcon,
  token,
  volume,
  borrowers,
  lenders,
  interests
}) => {
  return (
    <ProjectCardStyleWrapper>
      <div className="tier_system_content">
        <ul className="tier_system_list">
          <li aria-label="TIERS :" className="tier-thumb"> 
              <img src={coinIcon} alt="icon" className="img-fluid" /> 
            <strong>{token}</strong>
          </li>
          <li aria-label="volume :" className="staking-item">$ {volume}</li>
          <li aria-label="borrowers :" className="allocation-item">{borrowers}</li>
          <li aria-label="lenders :" className="requirements-item">{lenders}</li>
          <li aria-label="interests :" className="kyc-item">{interests}</li>
          <CardHover />
        </ul>
      </div>
    </ProjectCardStyleWrapper>
  );
};

export default ProjectCard;
