import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import nextArrowIcon from "assets/images/icons/next-arrow.png"
import ProjectCard from "../ProjectCard/ProjectCard";
import ProjectItemsStyleWrapper from "./ProjectItems.style";
import projectsData from "assets/data/projects/dataV6";

import coinIcon1 from "assets/images/project/previous-image.png"
import coinIcon2 from "assets/images/project/previous-image2.png"
import coinIcon3 from "assets/images/project/previous-image3.png"
import coinIcon4 from "assets/images/project/chain.png"
import coinIcon from "assets/images/project/project-single-image.png";
import { Children } from "react";

const ProjectItems = ({ loans }) => {
  const data = !loans ? [] : [{
    loanStatus: "PENDING",
    loans: loans.filter(l => l.status == "PENDING").map(l => Object.assign(l, { coinIcon }))
  }, {
    loanStatus: "ACTIVE",
    loans: loans.filter(l => l.status == "ACTIVE").map(l => Object.assign(l, { coinIcon }))
  }, {
    loanStatus: "DEFAULTED",
    loans: loans.filter(l => l.status == "DEFAULTED").map(l => Object.assign(l, { coinIcon }))
  }, {
    loanStatus: "REPAID",
    loans: loans.filter(l => l.status == "REPAID").map(l => Object.assign(l, { coinIcon }))
  }]

  return (
    <ProjectItemsStyleWrapper>
      <div className="container">
        <div className="row">
          <ul className="menu-list">
            <li>Currency</li>
            <li>Capital</li>
            <li>Interest</li>
            <li>Frequency</li>
            <li>Deadline</li>
          </ul>
        </div>
        <div className="projects-row">
          <Tabs>
            <TabList>
              <div className="tab_btn_wrapper">
                {data?.map((child, i) => (
                  <Tab key={i}>
                    <button>
                      {child.loanStatus}
                    </button>
                  </Tab>
                ))}
              </div>
              <div className="item_sorting_list">
                <button>
                  All type
                  <img src={nextArrowIcon} alt="icon" />
                  <ul className="sub-menu">
                    <li>Amortized</li>
                    <li>Bullet</li>
                    <li>All types</li>
                  </ul>
                </button>
                <button>
                  All currencies
                  <img src={nextArrowIcon} alt="icon" />
                  <ul className="sub-menu">
                    <li><img src={coinIcon1} alt="icon" /> NEAR</li>
                    <li><img src={coinIcon2} alt="icon" /> ETH</li>
                    <li><img src={coinIcon3} alt="icon" /> WBTC</li>
                    <li><img src={coinIcon3} alt="icon" /> REF</li>
                    <li><img src={coinIcon4} alt="icon" /> All Currencies</li>
                  </ul>
                </button>
              </div>
            </TabList>

            {data?.map((item, i) => (
              <TabPanel key={i} className="row tabs-row">
                {item.loans?.map((loan, i) => (
                  <div key={i} className="col-md-12">
                    <ProjectCard {...loan} />
                  </div>
                ))}
              </TabPanel>
            ))}

          </Tabs>

        </div>
      </div>
    </ProjectItemsStyleWrapper>
  );
};

export default ProjectItems;
