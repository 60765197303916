import { Fragment, useState, useEffect } from "react";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa"
import Button from "components/button";
import ApplyFormStyleWrapper from "./ApplyFrom.style"

const ApplyForm = ({ isSignedIn, nearLoans, wallet }) => {
    const minDate = new Date()
    minDate.setSeconds(0)
    const maxDate = new Date()
    maxDate.setMonth(maxDate.getMonth()+1)
    maxDate.setSeconds(0)
    const minDateISO = minDate.toISOString().split(".")[0]
    const maxDateISO = maxDate.toISOString().split(".")[0]

    // controlled input 
    const [loan, setLoan] = useState({ mode:"BORROW", type:"AMORTIZED", currency:"NEAR" })

    const handleChange = e => {
        setLoan({...loan, [e.target.name]: e.target.value})
    }

    const createLoan = async (e) => {
        e.preventDefault()
        const data = {...loan}
        if (loan.expiration) data.expiration = new Date(loan.expiration).getTime()
        try {
            const result = await nearLoans.createLoan(data.mode, data.type, data.currency, data.ft, data.capital, data.rate, data.frequency, data.duration, data.expiration)
        } catch (err) {
            alert("an error occured")
            console.log("error creating loan")
            console.log(err)
        }
    }

    return (
        <ApplyFormStyleWrapper>
            {loan && <form onSubmit={createLoan}>
                <div className="form_widgets">
                    {/* <h3>Loan Technical Features</h3> */}
                    <div className="form-group">
                        <label htmlFor="capital">Capital</label>
                        <input type="number" id="capital" placeholder="Loan capital" className="form-control" min="1" name="capital" value={loan.capital} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="capital">Fungible Token Account</label>
                        <input type="text" id="ft" placeholder="ft account address ( example : usdc.fakes.testnet )" className="form-control" name="ft" value={loan.ft} onChange={handleChange} />
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="currency">Currency</label>
                        <select id="currency" className="form-control" placeholder="Loan Currency" name="currency" value={loan.currency} onChange={handleChange}>
                            <option value="TOKEN1">TOKEN 1</option>
                            <option value="TOKEN2">TOKEN 2</option>
                            <option value="NEAR">NEAR</option>
                            <option value="ETH">ETH</option>
                            <option value="WBTC">WBTC</option>
                            <option value="REF">REF</option>
                        </select>
                    </div> */}
                    
                    <div className="form-group">
                        <label htmlFor="type">Type</label>
                        <select id="type" className="form-control" placeholder="Loan Type" name="type" value={loan.type} onChange={handleChange}>
                            <option value="AMORTIZED">AMORTIZED</option>
                            {/* <option value="BULLET">BULLET</option> */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="interest-rate">Interest Rate (%)</label>
                        <input type="number" id="interest-rate" placeholder="Loan interest rate" className="form-control percent" min="1" max="50" step="0.01" name="rate" value={loan.rate} onChange={handleChange} required />
                        <span className="percent"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="duration">Duration</label>
                        <input type="number" id="duration" placeholder="Loan duration ( days )" className="form-control days" min="7" max="365" step="1" name="duration" value={loan.duration} onChange={handleChange} required />
                    </div>
                    {loan.capital && loan.rate && <div className="form-group">
                        <label htmlFor="interest-rate">Loan Total Repayment (&nbsp;in&nbsp;{loan.currency}&nbsp;)</label>
                        <p class="highlight"><b>{Math.floor(loan.capital * ( 1 + loan.rate * 0.01)) }</b></p>
                    </div>}
                    {loan.capital && loan.rate && loan.duration && <div className="form-group">
                        <label>Daily Interest (&nbsp;in&nbsp;{loan.currency}&nbsp;)</label>
                        <p class="highlight"><b>{Math.floor(loan.capital * ( 1 + loan.rate * 0.01) / loan.duration)}</b></p>
                    </div>}
                    {loan.capital && loan.rate && loan.duration && <div className="form-group">
                        <label>APY (&nbsp;in&nbsp;{loan.currency}&nbsp;)</label>
                        <p class="highlight"><b>{(100 * Math.floor(365 * loan.capital * loan.rate * 0.01 / loan.duration) / Math.floor(loan.capital)).toFixed(0)}&nbsp;%</b></p>
                    </div>}
                    {/* <div className="form-group">
                        <label htmlFor="interest-frequency">Interest Collection Frequency</label>
                        <input type="number" id="interest-frequency" placeholder="Loan interest frequency ( days )" className="form-control days" min="1" max="30" step="1" name="frequency" value={loan.frequency} onChange={handleChange} required />
                    </div> */}
                    <p>The loan repayment formula is <span class="highlight">repayment&nbsp;=&nbsp;capital&nbsp;*&nbsp; ( 1 + interest rate )</span></p>
                    {/* <div className="form-group">
                        <label htmlFor="duration">Loan Request Expiration ( UTC )</label>
                        <input type="datetime-local" id="duration" placeholder="Loan request expiration" className="form-control" min={minDateISO} max={maxDateISO} name="expiration" value={loan.expiration} onChange={handleChange} required />
                    </div> */}
                </div>

                {/* <br/><br/><hr/><br/>

                <div className="form_widgets form_check">
                    <h3>ABOUT YOUR PROJECT ( OPTIONAL )</h3>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input type="text" id="title" placeholder="Project title" className="form-control" name="title" value={loan.title} onChange={handleChange} />
                    </div>
                    <div className="input_field_wrapper form-group col-md-12">
                        <label htmlFor="description">Description</label>
                        <textarea
                            name="description"
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="Description of your project"
                            value={loan.capital} 
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="capital">External Link</label>
                        <input type="text" id="link" placeholder="External link to the project" className="form-control" min="1" name="link" value={loan.link} onChange={handleChange} />
                    </div>
                </div> */}

                {/* <div className="form_widgets form_check">
                    <h5>BLOCKCHAIN/PLATFORM</h5>
                    <label>Binance Smart Chain
                        <input type="radio" name="platfrom" value="binance" />
                    </label>
                    <label>Solana
                        <input type="radio" name="platfrom" value="solana" />
                    </label>
                    <label>Ethereum
                        <input type="radio" name="platfrom" value="ethereum" />
                    </label>
                    <label>Polygon (Matic)
                        <input type="radio" name="platfrom" value="polygon" />
                    </label>
                </div> */}

                {/* <div className="form_widgets form_check">
                    <h5>PROJECT STATUS</h5>

                    <label>Just an initial idea
                        <input type="radio" name="projectStatus" value="initial" />
                    </label>
                    <label>Idea with White Paper
                        <input type="radio" name="projectStatus" value="whitePaper" />
                    </label>
                    <label>In early development
                        <input type="radio" name="projectStatus" value="development" />
                    </label>
                    <label>Ready to launch
                        <input type="radio" name="projectStatus" value="production" />
                    </label>
                </div> */}

                {/* <div className="form_widgets form_check">
                    <h5>HAVE YOU ALREADY RAISED FUNDS ?</h5>

                    <label>Yes
                        <input type="radio" name="funds" value="yes" />
                    </label>
                    <label>No
                        <input type="radio" name="funds" value="no" />
                    </label>
                </div> */}

                {/* <div className="form_widgets form_check">
                    <h5>IS YOUR TEAM ANON OR PUBLIC</h5>
                    <label>Anon
                        <input type="radio" name="visibility" value="anon" />
                    </label>
                    <label>Fully Public
                        <input type="radio" name="visibility" value="Public" />
                    </label>
                    <label>Mixed
                        <input type="radio" name="visibility" value="mixed" />
                    </label>
                </div> */}

                {/* <div className="form_widgets">
                    <div className="form-group">
                        <label htmlFor="fundterget">TARGET RAISE</label>
                        <input type="text" id="fundterget" placeholder="How much are you planning on raising on the IGO?" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="projectLink">PROJECT WEBSITE / LINK TO WHITEPAPER</label>
                        <input type="text" id="projectLink" placeholder="Enter link" className="form-control" />
                    </div>
                </div> */}

                {/* <div className="form_widgets">
                    <div className="form-group">
                        <label htmlFor="telegram">TELEGRAM GROUP</label>
                        <div className="input_with_icon">
                            <div className="input_social_icon">
                                <FaTelegramPlane />
                            </div>
                            <input type="text" id="telegram" placeholder="Enter telegram group link" className="form-control" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="telegram">Project Twitter</label>
                        <div className="input_with_icon">
                            <div className="input_social_icon">
                                <FaTwitter />
                            </div>
                            <input type="text" id="twitter" placeholder="Enter twitter link" className="form-control" />
                        </div>
                    </div>
                </div> */}

                {/* <br/><br/><hr/><br/> */}

                <Button variant="blue" type="submit" lg>
                    Submit Loan
                </Button>
            </form>}
        </ApplyFormStyleWrapper>
    )
}

export default ApplyForm;