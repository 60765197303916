import { Fragment, useState, useEffect } from "react";
import { SectionTitle, SectionTitleWrapper } from "components/sectionTitle";
import ProjectCard from "./ProjectCard/ProjectCard";
// import data from "assets/data/allocation/dataV1";
import AllocationStyleWrapper from "./Allocation.style";
import coinIcon from "assets/images/project/project-single-image.png";


const Allocation = ({ stats }) => {
  const data = !stats ? [] : Object.entries(stats).map(([token, tokenStats]) => Object.assign({coinIcon, token, ...tokenStats})).filter(tokenStats => tokenStats.volume > 0)

  return (
    <AllocationStyleWrapper>
      <div className="container">
        <SectionTitleWrapper>
          <SectionTitle title="LOANS VOLUME" subtitle="STATISTICS" />
        </SectionTitleWrapper>
        <div className="row">
          <ul className="menu-list">
            <li>TOKEN</li>
            <li>VOLUME</li>
            <li>BORROWERS</li>
            <li>LENDERS</li>
            <li>APY</li>
          </ul>
        </div>
        <div className="row align-items-center projects-row">
          {data && data.map((tokenStats,i) => (
            <div key={i} className="col-lg-12 col-md-6">
              <ProjectCard {... tokenStats } />
            </div>
          ))}
        </div>
      </div>
    </AllocationStyleWrapper>
  );
};

export default Allocation;
