const data = [
  {
    title: "What is a TradFi Loan ?",
    text: "In traditional finance the term loan refers to a type of credit vehicle in which a sum of money is lent to another party in exchange for future repayment of the value or principal amount. In many cases, the lender also adds interest or finance charges to the principal value which the borrower must repay in addition to the principal balance.",
  },
  {
    title: "What is a DeFi loan ?",
    text: "In decentralized finance, a loan follows the same usage than its TradFi counterpart. However it deals with crypto assets instead of traditional money. All of its internal rules are also implemented into smart contracts instead of institutional financial actors.",
  },
  {
    title: "What is an amortized loan ?",
    text: "An amortized loan is a type of loan with scheduled, periodic payments that are applied to both the loan's principal amount and the interest accrued. An amortized loan payment first pays off the relevant interest expense for the period, after which the remainder of the payment is put toward reducing the principal amount. Common amortized loans include auto loans, home loans, and personal loans from a bank for small projects or debt consolidation.",
  },
  {
    title: "What is a bullet loan ?",
    text: "A bullet loan is commonly referred to as a balloon loan that requires a balloon payment, usually a large balance, and a final payment due at the maturity of a loan. Throughout the borrowing period, the only payment made, if any, is the interest expense, and the original principal borrowed is paid at the end of the lending term. Bullet loans are more common in commercial real estate than in residential real estate.",
  },
  {
    title: "How Near Loans does manage loans ?",
    text: "Under the hood Near Loans is made up of a set of smart contract that enforce the different loan mechanisms such as collateral locking, capital transfer and interest collection.",
  },
  {
    title: "Is Near Loans secured ?",
    text: "Near Loans critical operations are fully executed onchain and thus fully benefits from the Near blockchain security when it comes to transactions and token transfers. In order to guarantee the code quality, Near Loans smart contracts code is also going to be soon audited by a recognized blockchain smart contracts security assessment expert.",
  },
];

export default data;
