import { FaTelegramPlane, FaTwitter, FaDiscord,  FaGlobe} from "react-icons/fa"
import { BsMedium } from "react-icons/bs";

import projectThumb1 from "assets/images/project/project-image.png";
import projectThumb2 from "assets/images/project/project-image2.png";
import projectThumb3 from "assets/images/project/project-image3.png";
import projectThumb4 from "assets/images/project/project-image4.png";
import projectThumb5 from "assets/images/project/project-image5.png";
import projectThumb6 from "assets/images/project/project-image6.png";

import coinIcon1 from "assets/images/project/project-single-image.png";
import coinIcon2 from "assets/images/project/project-single-image2.png";
import coinIcon3 from "assets/images/project/project-single-image3.png";
import coinIcon4 from "assets/images/project/project-single-image4.png";
import coinIcon5 from "assets/images/project/project-single-image5.png";
import coinIcon6 from "assets/images/project/project-single-image6.png";

const data = [
    {
        thumb: projectThumb1,
        title: "Galaxy War",
        price: "0.59 BUSD",
        saleEnd: "08",
        coinIcon: coinIcon1,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "0.33 BUSD"
            },
            {
                 title: "Max allocation",
                 text: "900.00 BUSD"
            },
            {
                 title: "Targeted raise",
                 text: "200,000 BUSD"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb2,
        title: "Super Sidero",
        price: "0.13 BUSD",
        saleEnd: "03",
        coinIcon: coinIcon2,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "0.89 BUSD"
            },
            {
                 title: "Max allocation",
                 text: "300.00 BUSD"
            },
            {
                 title: "Targeted raise",
                 text: "899,900 BUSD"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb3,
        title: "Meta World",
        price: "0.33 BUSD",
        saleEnd: "05",
        coinIcon: coinIcon3,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "2.25 BUSD"
            },
            {
                 title: "Max allocation",
                 text: "1000.00 BUSD"
            },
            {
                 title: "Targeted raise",
                 text: "50,00,000 BUSD"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb4,
        title: "Fisrt Survivor",
        price: "0.89 BUSD",
        saleEnd: "12",
        coinIcon: coinIcon4,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "0.59 BUSD"
            },
            {
                 title: "Max allocation",
                 text: "399.00 BUSD"
            },
            {
                 title: "Targeted raise",
                 text: "50,0000 BUSD"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb5,
        title: "Cryowar Two",
        price: "0.45 BUSD",
        saleEnd: "14",
        coinIcon: coinIcon5,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "0.66 BUSD"
            },
            {
                 title: "Max allocation",
                 text: "800.00 BUSD"
            },
            {
                 title: "Targeted raise",
                 text: "999,0000 BUSD"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb6,
        title: "Gaia Everworld",
        price: "0.13 BUSD",
        saleEnd: "15",
        coinIcon: coinIcon6,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "0.25 BUSD"
            },
            {
                 title: "Max allocation",
                 text: "500.00 BUSD"
            },
            {
                 title: "Targeted raise",
                 text: "869,0000 BUSD"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
]

export default data;